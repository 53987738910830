<template>
  <v-container v-if="carregou && podeSeInscrever" fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card width="1200">
          <v-card-title class="justify-center titulo">
            {{ processo.nome }}
          </v-card-title>
          <v-divider/>
          <div v-if="!mostrarRecibo">
            <v-card-subtitle class="text-center">
              <v-icon color="accent"> mdi-clipboard-edit-outline</v-icon>
              <h2 style="color: #f28c00">Inscrição</h2>
            </v-card-subtitle>
            <div class="text-end text-caption mr-9">
              * Campos obrigatórios
            </div>
            <v-card-text>
              <InscricaoForm
                  :processo="processo"
                  @salvar="mostrarRecibo=true"
              />
            </v-card-text>
          </div>
          <div v-else>
            <v-card-subtitle class="text-center">
              <v-icon color="accent"> mdi-text-box-outline</v-icon>
              <h2 style="color: #f28c00">Recibo de Inscrição</h2>
            </v-card-subtitle>
            <Recibo
                :processoOid="processo.oid"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="carregou && !podeSeInscrever">
    <v-row align="center" class="mt-10" justify="center">
      <v-alert type="warning" prominent colored-border border="left">
        {{ mensagem }}
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import InscricaoForm from "@/components/pages/Inscricao/InscricaoForm";
import processoService from "@/services/processo.service";
import inscricaoService from "@/services/inscricao.service";
import {mapActions} from "vuex";
import Recibo from "@/components/pages/Inscricao/Recibo";

export default {
  name: "Inscricao",

  components: {Recibo, InscricaoForm},

  data() {
    return {
      inscricao: null,
      processo: null,
      recibo: {},
      mostrarRecibo: false,
      carregou: false,
      podeSeInscrever: true,
      mensagem: null
    };
  },

  async created() {
    let loader = this.$loading.show();

    await processoService
        .recuperarPodeSeInscreverNoProcesso(this.$route.params.oid)
        .then(response => {
          this.processo = response.data;
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.podeSeInscrever = false;
            this.mensagem = error.response.data.msg;
          } else {
            this.$router.push({name: "404"}).catch(() => {
            });
          }
        });

    if (this.processo) {
      await inscricaoService
          .recuperarInscricaoEspecificaUsuarioLogado(this.processo.oid)
          .then(response => {
            this.inscricao = response.data;
            this.mostrarRecibo = true;
          })
          .catch(() => {
          });
    }

    this.carregou = true;
    loader.hide();
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
  }
};
</script>
