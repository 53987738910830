<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">
        Ação Afirmativa
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center"
        >
          <v-col
              cols="9"
          >
            <span>
              Deseja concorrer à ação afirmativa - Escola Pública de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-switch
                v-model="acaoAfirmativa.modalidadeEscolar"
                :label="acaoAfirmativa.modalidadeEscolar ? 'Sim' : 'Não'"
            />
          </v-col>
        </v-row>
        <v-row v-if="acaoAfirmativa.modalidadeEscolar"
               justify="center" align="center">
          <v-col
              cols="9"
          >
            <span>
              Possui renda familiar per capita inferior ou igual a um salário mínimo de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-switch
                v-model="acaoAfirmativa.modalidadeRenda"
                :label="acaoAfirmativa.modalidadeRenda ? 'Sim' : 'Não'"
            />
          </v-col>

          <v-col
              cols="9"
          >
            <span>
              Se declara pertencente ao grupo de Pretos, Pardos e Indígenas de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-switch
                v-model="acaoAfirmativa.modalidadeRacial"
                :label="acaoAfirmativa.modalidadeRacial ? 'Sim' : 'Não'"
            />
          </v-col>

          <v-col
              cols="9"
          >
            <span>
              Se declara pessoa com deficiência de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-switch
                v-model="acaoAfirmativa.modalidadePCD"
                :label="acaoAfirmativa.modalidadePCD ? 'Sim' : 'Não'"
            />
          </v-col>

          <v-col
              cols="9"
          >
            <span>
              Se declara quilombola de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-switch
                v-model="acaoAfirmativa.modalidadeQuilombola"
                :label="acaoAfirmativa.modalidadeQuilombola ? 'Sim' : 'Não'"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AcaoAfirmativa",

  props: {
    acaoAfirmativa: {type: Object, required: true}
  },

  watch: {
    acaoAfirmativa: {
      handler() {
        this.$emit('input', this.acaoAfirmativa);
      },
      deep: true
    },

    "acaoAfirmativa.modalidadeEscolar": {
      handler() {
        if (!this.acaoAfirmativa.modalidadeEscolar) {
          this.acaoAfirmativa.modalidadeRenda = false
          this.acaoAfirmativa.modalidadeRacial = false
          this.acaoAfirmativa.modalidadePCD = false
          this.acaoAfirmativa.modalidadeQuilombola = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>