<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">Envio de Documentos</v-card-title>
      <slot name="instrucoes"/>
      <v-card-text>
        <p class="text-justify">
          Por favor, escolha os arquivos para fazer o upload. Os arquivos podem
          ser nos formatos <b>pdf, jpeg, jpg ou png</b> e você pode fazer upload de até
          <b>20 arquivos com o tamanho máximo de 10mb cada</b>.
          <br/>
          <br/>
          <b>
            Certifique-se de que adicionou todos os documentos necessários antes
            de finalizar a inscrição.
          </b>
        </p>
        <file-input
            v-model="arquivosEscolhidos"
            :max-file-size="10485760"
            :num-max-files="20"
            :ruleCampoObrigatorio="true"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FileInput from "@/components/base/FileInput";
import rules from "@/commons/rules";

export default {
  name: "EnvioDocumentosInscricao",
  components: {FileInput},
  data() {
    return {
      arquivosEscolhidos: []
    };
  },

  methods:{
    ...rules
  },

  watch: {
    arquivosEscolhidos: {
      handler() {
        this.$emit("input", this.arquivosEscolhidos);
      },
      deep: true
    }
  }
};
</script>
